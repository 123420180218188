import { useEffect } from "react";
import { useState } from "react";
import { LEAGUES } from "@/utils/constants";

const initialValue = {
  league: null,
  showNotifications: false,
  showUserProfile: false,
  showUserProfilePopOver: false,
  showOnboardingModal: false,
  showWelcomeBanner: true,
  profileTab: 'history'
}

const AppState = () => {
  const [data, setData] = useState(initialValue);

  useEffect(() => {
		try {
			const crypto_league = localStorage.getItem('crypto_league') ? localStorage.getItem('crypto_league') : null
			if (!crypto_league) setData({
        ...initialValue,
        league: LEAGUES[1]
      })
			else {
        setData({
          ...initialValue,
          league: LEAGUES.find((l) => l.id === crypto_league)
        })
      }
		} catch (err) {
			console.error(err)
		}
	}, [])

  useEffect(() => {
    if (data.league) {
      localStorage.setItem('crypto_league', data.league.id)
    }
  }, [data.league])

  const actions = {
    setAppLeague: (p: typeof LEAGUES[0]) => setData((prev) => ({...prev, league: p})),
    setShowNotifications: (p: boolean) => setData((prev) => ({...prev, showNotifications: p})),
    setShowUserProfile: (p: boolean) => setData((prev) => ({...prev, showUserProfile: p})),
    setShowOnboardingModal: (p: boolean) => setData((prev) => ({...prev, showOnboardingModal: p})),
    setShowWelcomeBanner: (p: boolean) => setData((prev) => ({...prev, showWelcomeBanner: p})),
    hideOverlays: () => setData((prev) => ({...prev, showUserProfile: false, showNotifications: false })),
    setShowUserProfilePopOver: (p: boolean) => setData((prev) => ({...prev, showUserProfilePopOver: p})),
    setProfileTab: (tab = 'history') => setData((prev) => ({...prev, profileTab: tab})),
  };
  return { data, actions };
};

export default AppState;
