import React, { useState } from "react";

const initialState = {
  email: "",
  password: "",
  wallet: null,
  profile: null,
  team: null,
  dob: null,
  loggedIn: false,
  newSignup: false,
  referralCode: '',
};
export default function AuthStore() {
  const [store, setAuth] = useState(initialState);

  const setEmail = (email) => setAuth((store) => ({ ...store, email }));
  const setWallet = (wallet) => setAuth((store) => ({ ...store, wallet }));
  const setName = (profile) => setAuth((store) => ({ ...store, profile }));
  const setTeam = (team) => setAuth((store) => ({ ...store, team }));
  const setDob = (dob) => setAuth((store) => ({ ...store, dob }));
  const setReferralCode = (referralCode) => setAuth((store) => ({ ...store, referralCode }));
  const setNewSignup = (isNewSignup) =>
    setAuth((store) => ({ ...store, newSignup: isNewSignup }));

  const setLoggedIn = (loggedIn) =>
    setAuth((store) => ({ ...store, loggedIn }));
  const setPassword = (password) =>
    setAuth((store) => ({ ...store, password }));
  const reset = () => setAuth(initialState);

  const actions = {
    setEmail,
    setWallet,
    setName,
    setTeam,
    setDob,
    setNewSignup,
    setLoggedIn,
    setPassword,
    setReferralCode,
    reset,
  };

  return {
    data: store,
    actions,
  };
}
